<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode? User__title : ''"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <UserAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="add"
                    v-if="addMode && newUserUnsaved"
                />
                <UserAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="add"
                    v-if="updateMode && updatedUserUnsaved"
                />
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="userForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            multiple
                            v-model="panel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.mainProfile') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <UserMainProfile v-if="loader"/>
                                    <template v-if="!loader">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg3 md5 py-1 class="d-flex flex-row offset-lg-2 offset-md-3 offset-xs-6 align-end">
                                                <img :src="'data:image/jpeg;base64,' + User__photo" alt="avatar" height="120" width="120" class="img-responsive rounded-circle" v-if="User__photo != null"/>
                                                <img src="/static/avatars/appic_user.jpg" alt="avatar" height="120" width="120" class="img-responsive rounded-circle" v-else/>
                                                <v-btn @click="openUpdatePictureDialog()" icon class="grey--text darken-4">
                                                    <v-icon>mdi-camera</v-icon>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.name') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                    :label="User__title ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                                    :value="User__title"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    @change="User__title = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.abbreviation') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                    :label="User__abbreviation ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                                    :value="User__abbreviation"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    @change="User__abbreviation = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.emailUserName') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-text-field
                                                    :label="User__email ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required, ...validationRules.email]"
                                                    :value="User__email"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    @change="User__email = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="addMode">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.password') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>{{ $t('message.autogenerated') }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="$can('access','UserAuthorizations')">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.role') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-select
                                                    :items="userRoles"
                                                    :label="User__role ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="User__role"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.country') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <v-autocomplete
                                                    :items="filteredCountries"
                                                    :label="User__country_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    autocomplete="new-password"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Country.name"
                                                    item-value="Country.id"
                                                    solo
                                                    required
                                                    v-model="User__country_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.status') }}</v-flex>
                                            <v-flex xs6 lg3 md5 py-1>
                                                <span v-if="User__userstatus_id == 1" class="green--text darken-1">{{ $t('message.userActive') }}</span>
                                                <span v-if="User__userstatus_id == 0" class="red--text lighten-1">{{ $t('message.suspended') }}</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex d-flex xs12>
                                                <v-btn
                                                    :loading="loading.add"
                                                    class="ml-3"
                                                    color="info"
                                                    small
                                                    @click="addUser()"
                                                    v-if="addMode == true && $can('create','User')"
                                                >{{ $t('message.saveNewUser') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.save"
                                                    class="ml-3"
                                                    color="info"
                                                    small
                                                    @click="saveUser()"
                                                    v-if="updateMode == true && $can('update','User')"
                                                >
                                                    {{ $t('message.saveChanges') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.suspend"
                                                    class="ml-3"
                                                    small
                                                    @click="suspendUser()"
                                                    v-if="updateMode == true && $can('access','UserAuthorizations')"
                                                >
                                                    {{ $t('message.suspend') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.reset"
                                                    class="ml-3"
                                                    color="warning"
                                                    small
                                                    @click="openUserResetPasswordDialog()"
                                                    v-if="updateMode == true && $can('access','UserAuthorizations')"
                                                >
                                                    {{ $t('message.resetPassword') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.suspend"
                                                    class="ml-3"
                                                    color="error"
                                                    small
                                                    @click="deleteUser()"
                                                    v-if="updateMode == true && $can('delete','User')"
                                                >
                                                    {{ $t('message.delete') }}
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="$can('update','User')">
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.appSettings') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.timeOut') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-select
                                                    :items="timeOutOptions"
                                                    :label="User__appic_timeout ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    autocomplete="new-password"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    v-model="User__appic_timeout"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex d-flex xs12>
                                            <v-btn
                                                    :loading="loading.save"
                                                    class="ml-3"
                                                    color="info"
                                                    small
                                                    @click="saveUser()"
                                                    v-if="updateMode == true && $can('update','User')"
                                            >
                                                {{ $t('message.saveChanges') }}
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="$can('access','UserAuthorizations')">
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.accessPermissionManagement') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <AccessPermissionManager :user-id="User__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="$can('access','UserAuthorizations')">
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.dataFilters') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <DataFiltersListing :user-id="User__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="$can('access','UserZohoReports')">
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.zohoReports') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <UserZohoReportsListing :user-id="User__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_user')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="users" :asset_id="User__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        />
        <UserPasswordReset
            :user-id="User__email"
            :user-name="User__title"
            :dialog.sync="userResetPasswordDialog"
            @dialog-closed="resetPasswordDialogClosed()"
            @reset-done="userPasswordReset()"
        />
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-model="updatePictureDialog"
            width="325"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.updateProfilePicture') }}</v-card-title>
                <v-card-text>
                    <v-layout row>
                        <v-col cols="12">
                            <vue-croppie-component
                                :enable-resize="false"
                                :enable-orientation="false"
                                :boundary="{ width: 250, height: 250}"
                                :viewport="{ width: 190, height: 190, type:'circle' }"
                                ref="croppieRef"
                            />
                        </v-col>
                    </v-layout>
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex flex-row justify-center">
                            <input @change="croppie" type="file" accept="image/png, image/jpeg, image/jpg"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer/>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="updatePictureDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.upload"
                        class="ml-3"
                        color="info"
                        small
                        @click="updateProfilePicture()"
                    >{{ $t('message.upload') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import UserAlert from "Components/Appic/UserAlert";
import {VueCroppieComponent} from 'vue-croppie';
import 'croppie/croppie.css';
import Input from "Views/ui-elements/Input";
import {api} from 'Api';
import DataFiltersListing from "Components/Appic/DataFiltersListing";
import router from "@/router";
import AccessPermissionManager from "Components/Appic/AccessPermissionManager";
import UserZohoReportsListing from "Components/Appic/UserZohoReportsListing";
import {validationRules} from "@/store/modules/appic/constants";

const DocumentHistory = () => import("Components/Appic/DocumentHistory");
const SimpleAlert = () => import("Components/Appic/SimpleAlert");
const UserPasswordReset = () => import("Components/Appic/UserPasswordReset");
const UserMainProfile = () => import("Components/Appic/Loading/UserMainProfile");

export default {
    name: "AddUser",
    components: {
        UserZohoReportsListing,
        AccessPermissionManager,
        DataFiltersListing,
        Input, UserAlert, UserPasswordReset, DocumentHistory, SimpleAlert, VueCroppieComponent, UserMainProfile},
    props: ['userId'],
    title: '',
    data() {
        return {
            croppieImage: '',
            dialogs: {
                error:  false,
                error_message: ""
            },
            loader: false,
            loading: {
                add: false,
                save: false,
                upload: false
            },
            newProfilePicture: null,
            panel: [0],
            pageKey: 0,
            // rules: {
            //     abbreviation: v => !!v || this.$t('message.required'),
            //     country: v => !!v || this.$t('message.required'),
            //     email: v => !!v || this.$t('message.required'),
            //     name: v => !!v || this.$t('message.required'),
            //     role: v => !!v || this.$t('message.required'),
            //     timeOut: v => !!v || this.$t('message.required')
            // },
            timeOutOptions: [
                {value: 0, text: 'none'},
                {value: 1, text: '1 hour'},
                {value: 2, text: '2 hours'},
                {value: 3, text: '3 hours'},
                {value: 5, text: '5 hours'}
            ],
            updatePictureDialog: false,
            userResetPasswordDialog: false,
            validForm: false
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'new'
            }
        },
        ...mapFieldsPrefixed('user',{
            User__abbreviation: 'User.abbreviation',
            User__appic_timeout: 'User.appic_timeout',
            User__country_id: 'User.country_id',
            User__email: 'User.email',
            User__id: 'User.id',
            User__is_superadmin: 'User.is_superadmin',
            User__photo: 'User.photo',
            User__role: 'User.role',
            User__title: 'User.title',
            User__userstatus_id: 'User.userstatus_id'
        },'statePrefix'),
        ...mapFields('user',{
            currentUserEmail: 'current.User.email',
            currentUserPhoto: 'current.User.photo',
            newUserSaved: 'newUserSaved',
            newUserUnsaved: 'newUserUnsaved',
            updatedUserSaved: 'updatedUserSaved',
            updatedUserUnsaved: 'updatedUserUnsaved'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters([
            'userRoles',
            'validationRules'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_user')
        },
        filteredCountries: function () {
            return this.allCountries.filter(c => [1,2,3,4,5].includes(c.Country.id))
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_user')
        }
    },
    methods: {
        ...mapActions('country',{
            getAllCountries: 'getAllCountries'
        }),
        ...mapActions('user',{
            createUser: 'createUser',
            getAllUsers: 'getAllUsers',
            getUserToUpdateById: 'getUserToUpdateById',
            resetAllUsers: 'resetAllUsers',
            resetNewUser: 'resetNewUser',
            resetUpdateState: 'resetUpdateState',
            suspendUserByEmailId: 'suspendUserByEmailId',
            updateUser: 'updateUser',
        }),
        addUser() {
            let valid = this.$refs.userForm.validate()
            if(valid) {
                this.loading.add = true
                this.createUser()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.userAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.newUserUnsaved = false
                            this.newUserSaved = true
                            this.resetNewUser().then(() => {
                                this.resetAllUsers().then(() => {
                                    router.push({
                                        name: 'update_user',
                                        params: {userId: response.user_id}
                                    }).catch(err => {
                                    })
                                })
                            })
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.userNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            }
        },
        croppie (evt) {
            let files = evt.target.files || e.dataTransfer.files;
            if (!files.length) return;

            let reader = new FileReader();
            reader.onload = evt => {
                this.$refs.croppieRef.bind({
                    url: evt.target.result
                });
            };
            reader.readAsDataURL(files[0]);
        },
        async deleteUser() {
            if(await this.$root.$confirm(this.$t('message.deleteUser') + ' ' + (this.User__title != null ? this.User__title : ''), this.$t('message.confirmations.continueUserDeleteAction'), {color: 'orange'})){
                this.deleteUserByEmailId(this.User__email)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.userDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetAllUsers()
                            this.getAllUsers()
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.userNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        loadUserById (val) {
            this.loader = true
            this.getUserToUpdateById(val)
                .then(() => {
                    this.loader = false
                    this.updatedUserUnsaved = false
                    this.newUserUnsaved = false
                })
                .catch(()=>{
                    this.loader = false
                    this.updatedUserUnsaved = false
                    this.newUserUnsaved = false
                    this.resetUpdateState()
                })
        },
        openUpdatePictureDialog() {
            this.updatePictureDialog = true
            this.croppieImage = ''
            this.newProfilePicture = null
        },
        openUserResetPasswordDialog() {
            this.userResetPasswordDialog = true
        },
        resetPasswordDialogClosed () {
            this.userResetPasswordDialog = false;
        },
        saveUser () {
            let valid = this.$refs.userForm.validate()
            if(valid) {
                this.loading.save = true
                this.updateUser()
                    .then((status) => {
                        if(status == 'success') {
                            this.$toast.success(this.$t('message.successes.userUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.updatedUserUnsaved = false
                            this.resetAllUsers()
                            this.getAllUsers()
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
            }
        },
        async suspendUser() {
            if(await this.$root.$confirm(this.$t('message.suspendUser') + ' ' + (this.User__title != null ? this.User__title : ''), this.$t('message.confirmations.continueUserSuspendAction'), {color: 'orange'})) {
                this.suspendUserByEmailId(this.User__email)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.userSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetAllUsers()
                            this.getAllUsers()
                        } else {
                            this.$toast.error(this.$t('message.errors.userNotSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.userNotSuspended'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        updateProfilePicture() {
            let options = {
                type: 'base64',
                size: 'viewport',
                format: 'jpeg'
            };
            this.$refs.croppieRef.result(options, output => {
                this.loading.upload = true
                this.newProfilePicture = this.croppieImage = output;
                api
                    .put('/users/' + this.User__email + '/profile-picture', {
                        picture: this.newProfilePicture
                    })
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.User__photo = response.data.photo
                            if(this.User__email == this.currentUserEmail) {
                                this.currentUserPhoto = response.data.photo
                            }

                            this.loading.upload = false
                            this.newProfilePicture = null
                            this.croppieImage = ''
                            this.updatePictureDialog = false
                            this.resetAllUsers()
                            this.getAllUsers()
                            this.$toast.success(this.$t('message.successes.userProfilePictureUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        } else {
                            this.loading.upload = false
                            this.$toast.error(this.$t('message.errors.userProfilePictureNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.loading.upload = false
                        this.$toast.error(this.$t('message.errors.userProfilePictureNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            });
        },
        userPasswordReset() {
            this.resetPasswordDialog = false;
        }
    },
    created () {
        if(this.allCountries.length == 0) this.getAllCountries()
        if(this.$route.matched.some(({name}) => name === 'update_user')) {
            this.loadUserById(this.userId)
        } else if(this.$route.matched.some(({name}) => name === 'add_user')){
            this.resetNewUser();
            this.newUserUnsaved = false;
        }
    },
    mounted () {
        this.$store.watch(
            function (state) {
                return state.appic.user.update.User
            },
            () => {
                this.updatedUserUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.user.new.User
            },
            () => {
                this.newUserUnsaved = true
            },
            {deep: true}
        )
        if(this.$route.matched.some(({name}) => name === 'update_user')){
            setInterval(() => {
                this.$title =  "User: " + this.User__title
            }, 2000)
        } else {
            this.$title =  this.$t('message.titles.newUser')
        }
    }
}
</script>

<style>
.v-dialog {
    min-width: 325px !important;
}
</style>