var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-overlay',{attrs:{"value":_vm.loading.permissions,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('div',{staticClass:"pt-3"},[_vm._v(_vm._s(_vm.$t('message.userRole') + ': ')),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.userRoles.find(function (r) { return r.value == _vm.User__role; }).text))])]),_c('v-row',{staticClass:"pt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-sm pb-2"},[_vm._v(_vm._s(_vm.$t('message.defaultPermissions')))]),_c('v-data-table',{staticClass:"appic-table-light specification-table mr-3",attrs:{"headers":_vm.headers.default,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items":_vm.DefaultAccessPermissions,"calculate-widths":"","dense":"","disable-pagination":"","group-by":"subject","item-key":"item.index"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
return [_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":"2"}},[_vm._v(_vm._s(group))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.action))])]}},{key:"item.access",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"false-value":0,"input-value":item.access,"label":item.access == 1 ? _vm.$t('message.yes') : _vm.$t('message.no'),"true-value":1,"value":item.access,"dense":"","hide-details":"auto","flat":""},on:{"change":function($event){return _vm.updateUserAccess('default',item.idx, item.index, $event)}}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-sm"},[_vm._v(" "+_vm._s(_vm.$t('message.customPermissions'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.addAccessPermissionDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"green--text darken-1"},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addPermission')))])])],1),_c('v-data-table',{staticClass:"appic-table-light specification-table",attrs:{"headers":_vm.headers.custom,"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"items":_vm.CustomAccessPermissions,"calculate-widths":"","dense":"","disable-pagination":"","group-by":"subject","item-key":"item.index"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
return [_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":"3"}},[_vm._v(_vm._s(group))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.action))])]}},{key:"item.access",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"false-value":0,"input-value":item.access,"label":item.access == 1 ? _vm.$t('message.yes') : _vm.$t('message.no'),"true-value":1,"value":item.access,"dense":"","hide-details":"auto","flat":""},on:{"change":function($event){return _vm.updateUserAccess('custom',item.idx, item.index, $event)}}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteCustomAccessPermission(item.index, item.id)}}},on),[_c('v-icon',{attrs:{"color":"grey darken-1","small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.deleteThisItem')))])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","transition":"dialog-bottom-transition","overlay":"false","scrollable":"","max-width":"400px !important"},model:{value:(_vm.addAccessPermissionDialog),callback:function ($$v) {_vm.addAccessPermissionDialog=$$v},expression:"addAccessPermissionDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"success white--text"},[_c('span',[_vm._v(_vm._s(_vm.$t('message.addAbility')))])]),_c('v-card-text',[_c('v-form',{ref:"accessPermissionForm",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-layout',{attrs:{"row":"","pt-3":""}},[_c('v-flex',{staticClass:"align-center",attrs:{"xs6":"","lg4":"","md5":"","pl-5":"","py-1":""}},[_vm._v(_vm._s(_vm.$t('message.ability')))]),_c('v-flex',{attrs:{"xs6":"","lg8":"","md7":"","py-1":""}},[_c('v-select',{staticStyle:{"width":"300px"},attrs:{"items":_vm.standardAbilities,"rules":[_vm.rules.newAbility],"dense":"","hide-details":"auto","solo":""},model:{value:(_vm.newAbility),callback:function ($$v) {_vm.newAbility=$$v},expression:"newAbility"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"default","small":""},on:{"click":function($event){_vm.addAccessPermissionDialog = false}}},[_vm._v(_vm._s(_vm.$t('message.dismiss')))]),_c('v-btn',{attrs:{"loading":_vm.loading.add,"color":"info","small":""},on:{"click":function($event){return _vm.addUserAccess()}}},[_vm._v(_vm._s(_vm.$t('message.save')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }