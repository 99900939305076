<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-overlay
                :value="loading.reports"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-row no-gutters class="py-0">
                <v-col cols="12" class="col-md-3 col-xs-12">
                    <div class="font-sm py-3 d-flex flex-row">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="addUserZohoReportDialog = true"
                                >
                                    <v-icon class="green--text darken-1">add</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.addZohoReport') }}</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    icon
                                    v-on="on"
                                    @click="openSortUserZohoReportDialog()"
                                >
                                    <v-icon class="green--text darken-1">sort</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.sortReports') }}</span>
                        </v-tooltip>
                    </div>
                    <v-data-table
                        :headers="headers"
                        :hide-default-header="hideDefaultHeader"
                        :hide-default-footer="hideDefaultFooter"
                        :items="UserZohoReports"
                        :sort-by="['UserZohoReport.order_id']"
                        class="appic-table-light specification-table"
                        dense
                        disable-pagination
                        item-key="UserZohoReport.id"
                    >
                        <template v-slot:item.UserZohoReport.title="{ item }">
                            <span class="font-weight-bold">{{ item.UserZohoReport.title }}</span>
                        </template>
                        <template v-slot:item.UserZohoReport.filter="{ item }">
                            <span v-if="item.UserZohoReport.filter_field == 1">{{ item.UserZohoReport.filter }}</span>
                            <span v-else>{{ $t('message.na') }}</span>
                        </template>
                        <template v-slot:item.UserZohoReport.id="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="deleteUserZohoReport(item.UserZohoReport.id, item.UserZohoReport.title)" v-on="on" small>
                                        <v-icon color="grey darken-1" small>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.deleteThisItem') }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-sheet>
        <v-dialog
            persistent
            v-model="addUserZohoReportDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="575px"
            min-width="575px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">
                    <span>{{ $t('message.addZohoReport') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                        lazy-validation
                        ref="userZohoReportForm"
                        v-model="validForm"
                    >
                        <v-layout row pt-3>
                            <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.report') }}</v-flex>
                            <v-flex xs6 lg9 md7 py-1>
                                <v-select
                                    :items="allZohoReports"
                                    :rules="[rules.report]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    item-value="ZohoReport.id"
                                    item-text="ZohoReport.title"
                                    v-model="newUserZohoReport"
                                    @change="checkForReportFilter($event)"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row v-if="newUserZohoReportHasFilter">
                            <v-flex xs6 lg3 md5 pl-5 py-1 class="align-center">{{ $t('message.filter') }}</v-flex>
                            <v-flex xs6 lg9 md7 py-1>
                                <v-text-field
                                    :value="newUserZohoReportFilter"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="newUserZohoReportFilter = $event"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="default" small @click="addUserZohoReportDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addUserZohoReport()"
                    >{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            v-model="sortUserZohoReportDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="575px"
            min-width="575px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">
                    <span>{{ $t('message.arrangeZohoReports') }}</span>
                </v-card-title>
                <v-card-text>
                    <draggable
                        ghost-class="ghost"
                        v-model="sortedList"
                        class="mt-4 d-flex flex-row justify-space-between"
                        @change="itemDragged"
                    >
                        <template v-for="(item, index) in sortedList">
                            <div :key="'report_' + item.UserZohoReport.id" :class="'d-flex flex-row align-center flex-grow-1' + (activeItemId == item.UserZohoReport.id ? ' active-tab' : ' inactive-tab')">
                                <span class="mr-2 font-weight-bold">{{ '#' + (index + 1).toString() }}</span>
                                <div class="sort-document font-weight-bold" style="cursor: move">{{ item.UserZohoReport.title }}</div>
                            </div>
                        </template>
                    </draggable>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="default" small @click="sortUserZohoReportDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveUserZohoSorting()"
                    >{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {formatDate, log} from "Helpers/helpers";
import {api} from "Api";
import _ from 'lodash';
import draggable from 'vuedraggable';

export default {
    name: "UserZohoReportsListing",
    components: { draggable },
    props: ['userId'],
    data() {
        return {
            activeItemId: null,
            allZohoReports: [],
            addUserZohoReportDialog: false,
            UserZohoReports: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            filterValues: [],
            hideDefaultHeader: false,
            hideDefaultFooter: true,
            loading: {
                add: false,
                reports: false,
                save: false,
                values: false
            },
            newUserZohoReport: null,
            newUserZohoReportFilter: null,
            newUserZohoReportHasFilter: false,
            rules: {
                report: v => !!v || this.$t('message.required')
            },
            sortedList: [],
            sortUserZohoReportDialog: false,
            validForm: false
        }
    },
    computed: {
        formatDate: () => formatDate,
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'UserZohoReport.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.report'),
                    value: 'UserZohoReport.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 3,
                    text: this.$t('message.reportFilter'),
                    value: 'UserZohoReport.filter',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                }
            ]
        }
    },
    methods: {
        addUserZohoReport() {
            if(this.$refs.userZohoReportForm.validate()){
                this.loading.add = true
                api
                    .post("/users/" + this.userId + '/zoho-reports',{
                        zohoReport: {
                            user_id: this.userId,
                            report_id: this.newUserZohoReport,
                            filter: this.newUserZohoReportFilter
                        }
                    })
                    .then(response => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.settingUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.addUserZohoReportDialog = false
                            this.getUserZohoReports()
                        } else {
                            this.$toast.error(this.$t('message.errors.settingNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(err => {
                        this.$toast.error(err,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            }
            return true
        },
        checkForReportFilter(reportId) {
            let report = this.allZohoReports.find(zr => zr.ZohoReport.id == reportId)
            if(report && report.ZohoReport.filter_field == 1){
                this.newUserZohoReportHasFilter = true
            } else {
                this.newUserZohoReportHasFilter = false
            }
            return true
        },
        async deleteUserZohoReport(userZohoReportId, userZohoReportTitle) {
            if (await this.$root.$confirm(this.$t('message.deleteReport') + ": " + userZohoReportTitle , this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                api
                    .delete('/users/' + this.userId + '/zoho-reports/' + userZohoReportId)
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.$toast.success(this.$t('message.successes.userZohoReportDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.UserZohoReports = []
                            this.getUserZohoReports()
                        } else {
                            this.$toast.error(this.$t('message.errors.userZohoReportNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.userZohoReportNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
            return true
        },
        itemDragged({moved}) {
            this.activeItemId = moved.element.UserZohoReport.id
        },
        getUserZohoReports() {
            return new Promise((resolve, reject) => {
                api
                    .get('/users/' + this.userId + '/zoho-reports')
                    .then(response => {
                        this.UserZohoReports = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getAllZohoReports() {
            api
                .get('/admin/zoho-reports')
                .then((response) => {
                    this.allZohoReports = response.data.data
                })
                .catch((e) => {
                    log(e)
                })
        },
        openSortUserZohoReportDialog() {
            this.sortedList = _.cloneDeep(this.UserZohoReports)
            this.sortedList.sort((a, b) => {
                if(a.UserZohoReport.order_id < b.UserZohoReport.order_id) return -1
                if(a.UserZohoReport.order_id > b.UserZohoReport.order_id) return 1
                return 0
            })
            if(this.sortedList.length > 0) this.activeItemId = this.sortedList[0].UserZohoReport.id
            this.sortUserZohoReportDialog = true
        },
        saveUserZohoSorting() {
            const sortList = this.sortedList.map( (item, index) => (
                {
                    sortId: index + 1,
                    report: item.UserZohoReport.title,
                    id: item.UserZohoReport.id
                }
            ))
            api
                .put('/users/' + this.userId + '/zoho-reports/sorting', {
                    sortList: sortList
                })
                .then((status) => {
                    if(status.data.status == 'success'){
                        this.$toast.success(this.$t('message.successes.userZohoReportSortingUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.save = false
                        this.getUserZohoReports()
                            .then(()=>{
                                this.loading.reports = false
                                this.sortUserZohoReportDialog = false
                            })
                            .catch(()=>{
                                this.loading.reports = false
                            })
                    } else {
                        this.$toast.error(this.$t('message.errors.userZohoReportSortingNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    }
                })
                .catch((err) => {
                    this.$toast.error(err,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.save = false
                })
        }
    },
    watch: {
        addUserZohoReportDialog(value) {
            if(value == true){
                this.getAllZohoReports()
            } else {
                this.newUserZohoReport = null
                this.newUserZohoReportFilter = null
                this.newUserZohoReportHasFilter = false
                this.allZohoReports = []
            }
        }
    },
    created(){
        this.loading.reports = true
        this.getUserZohoReports()
            .then(()=>{
                this.loading.reports = false
            })
            .catch(()=>{
                this.loading.reports = false
            })
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.active-tab {
    border-top: grey 1px solid;
    border-left: grey 1px solid;
    border-right: grey 1px solid;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
}
.inactive-tab {
    border-bottom: grey 1px solid;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: lightgray;
}
</style>